import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { Box, Button, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
/* import AdminLiteDashboard from './AdminLiteDashboard'; // Import your AdminLiteDashboard layout */
const CarouselImageManagement = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState(null);
  const [carouselImages, setCarouselImages] = useState([]);
  const [editId, setEditId] = useState(null);

 

  // Fetch all carousel images
  const fetchImages = async () => {
    try {
      //const response = await axios.get('http://localhost:5000/api/carousel-images');
      //24-10-24//
      const response = await axios.get('https://apiviztech.in:6101/api/carousel-images');
      setCarouselImages(response.data);
    } catch (error) {
      console.error('Error fetching images:', error);
    }
  };

  useEffect(() => {
    fetchImages();
  }, []);

  const handleImageUpload = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    formData.append('image', image);

    try {
      //const response = await axios.post('http://localhost:5000/api/carousel', formData, {
      //24-10-24//
      const response = await axios.post('https://apiviztech.in:6101/api/carousel', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert(response.data.message);
      fetchImages();  // Refresh carousel images
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  const handleDelete = async (id) => {
    try {
      //await axios.delete(`http://localhost:5000/api/carousel/${id}`);
      //24-10-24//
      await axios.delete(`https://apiviztech.in:6101/api/carousel/${id}`);
      alert('Image deleted');
      fetchImages();
    } catch (error) {
      console.error('Error deleting image:', error);
    }
  };

  const handleEdit = (image) => {
    setTitle(image.title);
    setDescription(image.description);
    setEditId(image.id);
  };
  const handleUpdate = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('title', title);
    formData.append('description', description);
    if (image) {
      formData.append('image', image); // Include the image in the formData only if a new image is selected
    }
  
    try {
      //await axios.put(`http://localhost:5000/api/carousel/${editId}`, formData, {
      //24-10-24//
      await axios.put(`https://apiviztech.in:6101/api/carousel/${editId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      alert('Image details updated');
      setTitle('');
      setDescription('');
      setImage(null); // Reset the image state
      setEditId(null);
      fetchImages(); // Refresh the list of carousel images
    } catch (error) {
      console.error('Error updating image details:', error);
    }
  };
  /* const handleUpdate = async (e) => {
    e.preventDefault();

    try {
      await axios.put(`http://localhost:5000/api/carousel/${editId}`, { title, description });
      alert('Image details updated');
      setTitle('');
      setDescription('');
      setEditId(null);
      fetchImages();
    } catch (error) {
      console.error('Error updating image details:', error);
    }
  }; */
 /*  const resetForm = () => {
    setTitle('');
    setDescription('');
    setImage(null);
    setEditId(null);
}; */

  return (
  
    <Box sx={{ p: 3 }}>
      <Typography variant="h5" sx={{ mb: 3 }}>Carousel Image Management</Typography>

      <form onSubmit={editId ? handleUpdate : handleImageUpload}>
        <TextField
          label="Title"
          variant="outlined"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
        <TextField
          label="Description"
          variant="outlined"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          fullWidth
          sx={{ mb: 2 }}
        />
       {/*  {!editId && (
          <input
            type="file"
            accept="image/*"
            onChange={(e) => setImage(e.target.files[0])}
            style={{ marginBottom: '20px' }}
          />
        )} */}
        <input
    type="file"
    accept="image/*"
    onChange={(e) => setImage(e.target.files[0])}
    style={{ marginBottom: '20px' }}
  />
        <Button variant="contained" color="primary" type="submit">
          {editId ? 'Update Image Details' : 'Upload Image'}
        </Button>
      </form>

     {/*  <Grid container spacing={2} sx={{ mt: 4 }}>
        {carouselImages.map((img) => (
          <Grid item xs={12} sm={6} md={4} key={img.id}>
            <Box sx={{ position: 'relative', borderRadius: '10px', overflow: 'hidden', boxShadow: 3 }}>
              <img
                src={`http://localhost:5000${img.image_path}`}
                alt={img.title}
                style={{ width: '100%', height: '200px', objectFit: 'cover' }}
              />
              <Box sx={{ p: 2 }}>
                <Typography variant="h6">{img.title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {img.description}
                </Typography>
              </Box>
              <IconButton onClick={() => handleEdit(img)} sx={{ position: 'absolute', top: 10, right: 40 }}>
                <EditIcon />
              </IconButton>
              <IconButton onClick={() => handleDelete(img.id)} sx={{ position: 'absolute', top: 10, right: 10 }}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid>
        ))}
      </Grid> */}
{/*       <Grid container spacing={2} sx={{ mt: 4 }}>
  {carouselImages.map((img) => (
    <Grid item xs={12} sm={6} md={4} key={img.id}>
      <Box
        sx={{
          position: 'relative',
          borderRadius: '10px',
          overflow: 'hidden',
          boxShadow: 3,
          width: '100%',
          height: '300px', // Fixed card height
          display: 'block', // Ensure box takes full width
        }}
      >
        <img
          src={`http://localhost:5000${img.image_path}`}
          alt={img.title}
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover', // Ensures the image covers the box, cutting off excess
            display: 'block', // Removes any gap around the image
          }}
        />
        <Box sx={{ p: 2 }}>
          <Typography variant="h6">{img.title}</Typography>
          <Typography variant="body2" color="text.secondary">
            {img.description}
          </Typography>
        </Box>
        <IconButton onClick={() => handleEdit(img)} sx={{ position: 'absolute', top: 10, right: 40 }}>
          <EditIcon />
        </IconButton>
        <IconButton onClick={() => handleDelete(img.id)} sx={{ position: 'absolute', top: 10, right: 10 }}>
          <DeleteIcon />
        </IconButton>
      </Box>
    </Grid>
  ))}
</Grid> */}
<TableContainer component={Paper} sx={{ mt: 4 }}>
      <Table sx={{ minWidth: 650 }} aria-label="carousel table">
        <TableHead>
          <TableRow>
            <TableCell>Image</TableCell>
            <TableCell>Title</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {carouselImages.map((img) => (
            <TableRow key={img.id}>
              <TableCell>
                <img
                  //src={`http://localhost:5000${img.image_path}`}
                  //24-10-24//
                  src={`https://apiviztech.in:6101${img.image_path}`}
                  alt={img.title}
                  style={{ width: '100px', height: '100px', objectFit: 'cover', borderRadius: '10px' }}
                />
              </TableCell>
              <TableCell>{img.title}</TableCell>
              <TableCell>{img.description}</TableCell>
              <TableCell>
              <IconButton onClick={() => handleEdit(img)} aria-label="edit" color="primary">
  <EditIcon />
</IconButton>
<IconButton onClick={() => handleDelete(img.id)} aria-label="delete" color="secondary">
  <DeleteIcon />
</IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </Box>
   
  );
};

export default CarouselImageManagement;
