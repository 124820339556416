//src/admin/callback.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import * as XLSX from 'xlsx';
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Pagination,
  Stack,
} from '@mui/material';

const Callback = () => {
  const [mobileNumbers, setMobileNumbers] = useState([]);
  const [page, setPage] = useState(1);
  const rowsPerPage = 5;

  // Fetch mobile numbers from the backend
  const fetchMobileNumbers = async () => {
    try {
      const response = await axios.get('https://apiviztech.in:6101/api/mobile-numbers');
      setMobileNumbers(response.data);
    } catch (error) {
      console.error('Error fetching mobile numbers:', error);
    }
  };

  // Update status of a mobile number
  const handleStatusUpdate = async (id) => {
    try {
      await axios.put(`https://apiviztech.in:6101/api/update-status/${id}`);
      // Update the status locally after a successful API call
      setMobileNumbers((prevNumbers) =>
        prevNumbers.map((number) =>
          number.id === id ? { ...number, status: 1 } : number
        )
      );
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  // Pagination handler
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  // Export data to Excel
  const exportToExcel = () => {
    // Prepare the data for Excel
    const data = mobileNumbers.map((number) => ({
      'Mobile Number': number.mobile_number,
      Date: new Date(number.created_at).toLocaleDateString('en-GB'),
      Status: number.status === 0 ? 'Unanswered' : 'Answered',
    }));

    // Create a new workbook and add the data
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Mobile Numbers');

    // Export the Excel file
    XLSX.writeFile(workbook, 'Mobile_Numbers.xlsx');
  };
  useEffect(() => {
    fetchMobileNumbers();
  }, []);

  return (
    <Container>
      <Typography variant="h4" sx={{ mt: 4, textAlign: 'center' }}>
        Callback Mobile Numbers
      </Typography>
      <Stack direction="row" justifyContent="flex-end" sx={{ mb: 2 }}>
        <Button variant="contained" color="primary" onClick={exportToExcel}>
          Export to Excel
        </Button>
      </Stack>
      <TableContainer component={Paper} sx={{ mt: 2 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center"><strong>ID</strong></TableCell>
              <TableCell align="center"><strong>Mobile Number</strong></TableCell>
              <TableCell align="center"><strong>Date</strong></TableCell>
              <TableCell align="center"><strong>Status</strong></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {mobileNumbers
              .slice((page - 1) * rowsPerPage, page * rowsPerPage)
              .map((number) => (
                <TableRow key={number.id}>
                  <TableCell align="center">{number.id}</TableCell>
                  <TableCell align="center">{number.mobile_number}</TableCell>
                  <TableCell align="center">{new Date(number.created_at).toLocaleDateString('en-GB')}</TableCell>
                  <TableCell align="center">
                    <Button
                      variant="contained"
                      color={number.status === 0 ? 'error' : 'success'}
                      onClick={() => handleStatusUpdate(number.id)}
                    >
                      {number.status === 0 ? 'Unanswered' : 'Answered'}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Pagination
        count={Math.ceil(mobileNumbers.length / rowsPerPage)}
        page={page}
        onChange={handleChangePage}
        sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}
      />
    </Container>
  );
};

export default Callback;
