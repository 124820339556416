import React, { useState, useEffect, useRef } from 'react';
import { Container, Typography, Grid, Card, CardContent, Button, CardMedia, Avatar, Box, IconButton, TextField } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { ArrowBackIos, ArrowForwardIos } from '@mui/icons-material'; // Import the arrow icons
import axios from 'axios'; // Add Axios to make API calls
import '../components/styles/home.css'; // Add external CSS for custom styles */
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'; // Import useParams
const Home = () => {
  const { id } = useParams();  // Get the id parameter from the URL
  const [activeIndex, setActiveIndex] = useState(0);
  const [direction, setDirection] = useState('next'); // Used to control the animation direction
  const [isSliding, setIsSliding] = useState(false); // Track sliding animation
  const [whatsNew, setWhatsNew] = useState([]); // State to store "What's New" data
  const [notifications, setNotifications] = useState([]);
  const [images, setImages] = useState([]);
  const [mobile, setMobile] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const [advertisements, setAdvertisements] = useState([]);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  //const [filteredAd, setFilteredAd] = useState(null);
  const highlightedRef = React.createRef(); // Ref for the highlighted ad
  // Define the list of colors and fonts
const colors = ["#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#FFDD33"];
const fonts = ["Arial", "Verdana", "Georgia", "Courier New", "Times New Roman"];
//const [notificationStyles, setNotificationStyles] = useState([]);
const [globalStyle, setGlobalStyle] = useState({});
 /*  const images = [
    require('../assets/1.jpeg'),  
    require('../assets/2.jpeg'),
    require('../assets/3.jpeg')
  ]; */
// Fetch carousel images from the API
useEffect(() => {
  const fetchImages = async () => {
    try {
      //const response = await axios.get('http://localhost:5000/api/carousel-images');
      
      
     /*  const test=await axios.get('http://apiviztech.in:8444');
      console.log(test); */
      const response = await axios.get('https://apiviztech.in:6101/api/carousel-images');
      console.log(response);
      //const imagePaths = response.data.map(item => item.image_path); // Adjust based on your API response structure
      const imagePaths = response.data.map(item => `https://apiviztech.in:6101${item.image_path}`); // Append the server URL to the image path
      setImages(imagePaths);
    } catch (error) {
      console.error('Error fetching carousel images:', error);
    }
  };

  fetchImages();
}, []);

  const services = [
    { title: 'School Product', description: 'Innovative school management solutions.', link: '/services/school-product', img: require('../assets/services/school-man.jpg') },
    { title: 'Training', description: 'Professional IT training and skill development.', link: '/services/training', img: require('../assets/services/7 (2).jpeg') },
    { title: 'Development', description: 'Custom software and app development services.', link: '/services/development', img: require('../assets/services/2.jpeg') }
  ];

  const testimonials = [
    {
      name: "John Doe",
      feedback: "Infokshetra Technologies provided excellent support during our app development project. Highly recommended!",
      company: "ABC Corp",
      img: require('../assets/john.jpg') 
    },
    {
      name: "Jane Smith",
      feedback: "Their training program helped our team acquire the necessary skills for cloud computing. Great job!",
      company: "XYZ Ltd",
      img: require('../assets/jane.jpg') 
    },
    {
      name: "David Lee",
      feedback: "The school management system developed by Infokshetra is top-notch. It has streamlined our processes immensely.",
      company: "Learning Tree School",
      img: require('../assets/david.jpg') 
    }
  ];

  // Automatically move to the next testimonial after 5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 5000); 

    return () => clearInterval(interval); 
  }, [activeIndex]);

/*   const handleNext = () => {
    setDirection('next');
    setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
  };

  const handlePrev = () => {
    setDirection('prev');
    setActiveIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
  }; */
  const handleNext = () => {
    setIsSliding(true);
    setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % testimonials.length);
      setIsSliding(false);
    }, 500); // Match the duration of the animation
  };

  const handlePrev = () => {
    setIsSliding(true);
    setTimeout(() => {
      setActiveIndex((prevIndex) => (prevIndex - 1 + testimonials.length) % testimonials.length);
      setIsSliding(false);
    }, 500);
  };
 // Fetch "What's New" content from the server
 useEffect(() => {
  const fetchWhatsNew = async () => {
    try {
      //const response = await axios.get('http://localhost:5000/api/whatsnew'); // API call to fetch data from MySQL

      //24-10-24//
      const response = await axios.get('https://apiviztech.in:6101/api/whatsnew'); // API call to fetch data from MySQL
      setWhatsNew(response.data); // Assuming data comes as an array of new updates
    } catch (error) {
      console.error('Error fetching What\'s New data:', error);
    }
  };

  fetchWhatsNew();
}, []);

// Fetch notifications from the server
useEffect(() => {
  const fetchNotifications = async () => {
    try {
      //const response = await axios.get('http://localhost:5000/api/notifications'); // Replace with your API endpoint
      //24-10-24//
      const response = await axios.get('https://apiviztech.in:6101/api/notifications'); // Replace with your API endpoint
      
      setNotifications(response.data); // Assuming the API returns an array of notifications
     // setNotifications(response.data); // Assuming the API returns an array of notifications
    } catch (error) {
      console.error('Error fetching notifications:', error);
    }
  };

  fetchNotifications();
}, []);
useEffect(() => {
  // Generate a single random style for all notifications
  const randomStyle = {
    color: colors[Math.floor(Math.random() * colors.length)],
    fontFamily: fonts[Math.floor(Math.random() * fonts.length)],
  };
  setGlobalStyle(randomStyle);
}, [notifications]);
  // Function to handle mobile number submission
  const handleCallMe = async () => {
    const mobileRegex = /^[0-9]{10}$/; // Validate for 10-digit mobile numbers

    if (!mobileRegex.test(mobile)) {
      setError('Please enter a valid 10-digit mobile number');
      return;
    }

    setError(''); // Clear previous errors if any

    try {
      //const response = await axios.post('http://localhost:5000/api/mobile_numbers', { mobile_number: mobile });
      //24-10-24//
      const response = await axios.post('https://apiviztech.in:6101/api/mobile_numbers', { mobile_number: mobile });

      if (response.data.success) {
        alert('We will call you soon!');
        setMobile(''); // Clear the input field after successful submission
      }
    } catch (error) {
      console.error('Error submitting mobile number:', error);
    }
  };

/*   const handleNotificationClick = (notification) => {
    // Navigate to NotificationDetail.js with the selected notification data
    navigate('/notification-detail', { state: { notification } });
  }; */
  const handleNotificationClick = (title) => {
    const encodedTitle = encodeURIComponent(title);
    // Open NotificationDetail page in a new tab with the title as a URL parameter
    //window.open(`/notification-detail/${encodedTitle}`, '_blank');
    // Assuming you are using React Router
    //navigate(`/notification-detail/${encodedTitle}`);
    window.open(`#/notification-detail/${encodedTitle}`, '_blank');
    //navigate('/notification-detail', { state: { title } });
  };

/*   useEffect(() => {
    const fetchAdvertisements = async () => {
      try {
        const response = await axios.get('http://localhost:6101/api/advertisementsh');
        const ads = response.data;

        // Check if there's an ad ID from the URL
        if (id) {
          const ad = ads.find(ad => ad.ad_id === parseInt(id, 10));
          setFilteredAd(ad ? [ad] : []);  // Set filtered ad if found
        } else {
          setAdvertisements(ads);  // Otherwise, show all advertisements
        }
      } catch (error) {
        console.error('Error fetching advertisements:', error);
      }
    };

    fetchAdvertisements();
  }, [id]);  // Re-run the effect if `id` changes */

  useEffect(() => {
    const fetchAdvertisements = async () => {
      try {
        const response = await axios.get('https://apiviztech.in:6101/api/advertisementsh');
        setAdvertisements(response.data);
        setIsDataLoaded(true);
      } catch (error) {
        console.error('Error fetching advertisements:', error);
      }
    };

    fetchAdvertisements();
  }, []);
  // Scroll to the highlighted advertisement after data is loaded
  useEffect(() => {
    if (isDataLoaded && highlightedRef.current) {
      highlightedRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });
    }
  }, [advertisements,isDataLoaded, id]);
  //const adsToDisplay = filteredAd || advertisements;
   // Filter the advertisement by the ID passed in the URL
   //const filteredAd = advertisements.find((ad) => parseInt(id, 10) === ad.ad_id);
   //const filteredAd = advertisements.find((ad) => parseInt(id, 10) === ad.ad_id);

    // Return early if no advertisement is found
 /*  if (!filteredAd) {
    return (
      <Typography variant="h6" sx={{ mt: 4, textAlign: 'center' }}>
        No advertisement found.
      </Typography>
    );
  } */
    //const highlightedAd = advertisements.find((ad) => parseInt(id, 10) === ad.ad_id);

  return (

    <Container sx={{ mt: 4, maxWidth: 'lg'}}>
      {/* Scrolling Notification Bar */}
      <Box className="scrolling-bar">
        
        <Box className="scrolling-text">
          {notifications.length > 0 ? (
            notifications.map((notification, index) => (
              <Typography key={index} variant="body1" sx={{display: 'inline', mr: 3, cursor: 'pointer'
               }}
              onClick={() => handleNotificationClick(notification.message)}

              >
                {notification.message}
              </Typography>
            ))
          ) : (
            <Typography variant="body1" sx={{ mr: 3 }}>
              No new notifications at the moment.
            </Typography>
          )}
        </Box>
      </Box>
     
      {/* <Carousel autoPlay interval={4000} indicators={false}>
        {images.map((img, i) => (
          <img key={i} src={img} alt={`slide-${i}`} style={{ width: '100%', height: '400px' }} />
        ))}
      </Carousel> */}

      {/* Image Carousel with Rounded Frame */}
      <Grid container spacing={4} sx={{ mt: 1 }}>
        {/* <Grid item xs={8}> */}
        <Grid item xs={12} md={8}>
          <Box
           sx={{ 
            borderRadius: '15px', overflow: 'hidden', boxShadow: 4,
              width: '100%', // Ensure full width for mobile
              height: { xs: 'auto', md: '400px' }, // Height for mobile and desktop }}>
            }}
            >
            <Carousel autoPlay interval={4000} indicators={false}>
              {images.map((img, i) => (
                <img key={i} src={img} alt={`slide-${i}`} style={{ width: '100%', height: '400px', borderRadius: '15px' }} />
              ))}
            </Carousel>
          </Box>
        </Grid>

        {/* What's New Section with Scrolling Content */}
        {/* <Grid item xs={4}> */}
        <Grid item xs={12} md={4}>
          <Box sx={{ height: '400px', overflow: 'hidden', borderRadius: '15px', boxShadow: 4, backgroundColor: '#f8f9fa' }}>
            <Typography variant="h6" sx={{ textAlign: 'center', py: 1, backgroundColor: 'maroon', color: 'white' }}>
              What's New
            </Typography>

            <Box className="scrolling-content" sx={{ height: '350px', overflowY: 'auto', padding: 2 }}>
              {whatsNew.map((news, index) => (
                <Typography key={index} variant="body1" sx={{ mb: 2 }}>
                  {news.content}
                </Typography>
              ))}
            </Box>
          </Box>
        </Grid>
      </Grid>

{/* call me */}
<Grid item xs={12}>
  <Box 
    sx={{ 
      mt: 4, 
      p: 3, 
      borderRadius: '15px', 
      boxShadow: 4, 
      backgroundColor: '#f8f9fa', 
      display: 'flex', 
      alignItems: 'center', 
      justifyContent: { xs: 'center', md: 'space-between' }, // Stack vertically on mobile, space-between on desktop
      flexDirection: { xs: 'column', md: 'row' } // Column for mobile, row for desktop
    }}
  >
    {/* Column for Query Text */}
    <Typography 
      variant="h6" 
      sx={{ 
        color: 'maroon', 
        mr: { xs: 0, md: 2 }, // No right margin on mobile, some margin on desktop
        mb: { xs: 2, md: 0 }, // Add bottom margin on mobile to separate rows
        textAlign: { xs: 'center', md: 'left' } // Center text on mobile, left-align on desktop
      }}
    >
      Have Queries? Let Us Call You
    </Typography>

    {/* Column for Form Input and Button */}
    <Box 
      sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: { xs: 'center', md: 'flex-end' }, // Center on mobile, right-align on desktop
        width: { xs: '100%', md: 'auto' } // Full width on mobile, auto on desktop
      }}
    >
      <span style={{ marginRight: '8px' }}>+91</span>
      
      {/* Input field for mobile number */}
      <TextField
        type="tel"
        name="phone"
        className="form-control"
        placeholder="Mobile Number"
        id="phone"
        maxLength="10"
        value={mobile}
        onChange={(e) => setMobile(e.target.value)}
        error={!!error}
        helperText={error}
        inputProps={{ maxLength: 10 }} // Enforcing max length of 10 directly
        sx={{ width: '150px', mr: 2 }} // Set a fixed width for the input field
      />
      
      {/* Call Me Button */}
      <Button
        variant="contained"
        sx={{ backgroundColor: 'maroon', color: 'white', padding: '8px 16px', border: 'none', borderRadius: '5px' }}
        onClick={handleCallMe}
      >
        Call Me
      </Button>
    </Box>
  </Box>
</Grid>
      {/* Services Section */}
      <Typography variant="h4" sx={{ mt: 4, textAlign: 'center' }}>Our Services</Typography>
      <Grid container spacing={3} sx={{ mt: 2 }}>
        {services.map((service, i) => (
          <Grid item xs={12} sm={4} key={i}>
            <Card
              sx={{
                boxShadow: 3,
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'translateY(-10px)',
                  boxShadow: 6,
                },
              }}
            >
              <CardMedia
                component="img"
                height="140"
                image={service.img}
                alt={service.title}
              />
              <CardContent>
                <Typography variant="h5">{service.title}</Typography>
                <Typography>{service.description}</Typography>
                <Button variant="contained" href={service.link} sx={{ mt: 2 }}>Learn More</Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      


{/* Advertisements Section */}
{advertisements.length > 0 && (
        <section id="advertisements">
      <Typography variant="h4" sx={{ mt: 4, textAlign: 'center' }}>Advertisements</Typography>
      <Grid container spacing={3} sx={{ mt: 2, justifyContent: 'center' }}>
        {advertisements.length > 0 ? (
          advertisements.map((ad) => {
            const isHighlighted = parseInt(id, 10) === ad.ad_id; // Check if this ad matches the ID in the URL

            return (
              <Grid
                item
                xs={12}
               /*  sm={6}
                md={4} */
                /* sm={4} */
                key={ad.ad_id}
                sx={{ display: 'flex', justifyContent: 'center' }}
                ref={isHighlighted ? highlightedRef : null}  // Attach ref to the highlighted ad
              >
                <Card
                  sx={{
                    boxShadow: isHighlighted ? 6 : 3,  // Increase shadow for highlighted card
                    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                    transform: isHighlighted ? 'translateY(-10px)' : 'none',  // Move highlighted card up slightly
                    border: isHighlighted ? '2px solid #1976d2' : 'none', // Blue border for highlighted card
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    padding: '16px',
                  }}
                >
                  <CardContent sx={{ textAlign: 'center' }}>
                    <Typography variant="h5" gutterBottom>{ad.title}</Typography>
                  </CardContent>

                  <div 
                    style={{ 
                      display: 'flex', 
                      flexDirection: 'column', 
                      alignItems: 'center', 
                      gap: '8px' 
                    }}
                  >
                    {ad.images && JSON.parse(ad.images).map((img, index) => (
                      <img 
                        key={index} 
                        src={`https://apiviztech.in:6101${img}`}
                        alt={`ad-image-${index}`}
                        style={{
                          width: 'auto',
                          height: 'auto',
                          maxWidth: '100%',
                          borderRadius: '8px',
                        }}
                      />
                    ))}
                  </div>
                </Card>
              </Grid>
            );
          })
        ) : (
          <Typography variant="h6" sx={{ mt: 4, textAlign: 'center' }}>No advertisement found.</Typography>
        )}
      </Grid>
</section>
)}
        {/* What Our Clients Say Section with maroon background */}
        <Box sx={{ backgroundImage: `url(${require('../assets/b.jpg')})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', mt: 4, py: 5 }}>
        <Typography variant="h4" sx={{ textAlign: 'center', backgroundColor: 'maroon', color: 'white', padding: 2 }}>
          What Our Clients Say
        </Typography>

        <Box className={`testimonial-box ${direction}`} sx={{ position: 'relative', maxWidth: '600px', margin: '0 auto', padding: 3, textAlign: 'center' }}>
          {/* Client Feedback */}
          <Typography variant="body1" sx={{ fontStyle: 'italic', mb: 2 }}>
            "{testimonials[activeIndex].feedback}"
          </Typography>

          {/* Client Avatar */}
          <Avatar
            src={testimonials[activeIndex].img}
            alt={testimonials[activeIndex].name}
            sx={{ width: 100, height: 100, margin: '0 auto', mb: 2 }}
          />

          {/* Client Name */}
          <Typography variant="h6">{testimonials[activeIndex].name}</Typography>
          <Typography variant="body2" color="textSecondary">{testimonials[activeIndex].company}</Typography>

          {/* Next and Previous Arrows */}
          <IconButton
            onClick={handlePrev}
            sx={{ position: 'absolute', left: 0, top: '50%', transform: 'translateY(-50%)' }}
          >
            <ArrowBackIos />
          </IconButton>
          <IconButton
            onClick={handleNext}
            sx={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
          >
            <ArrowForwardIos />
          </IconButton>
        </Box>
      </Box>
    </Container>
   /*  </Container> */
  );
};

export default Home;
