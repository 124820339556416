// src/NotificationDetail.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Container, Typography, Card, CardContent, CardHeader, Box } from '@mui/material';
import axios from 'axios';

const NotificationDetail = () => {
    const { title } = useParams();
    //console.log(title);
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        //console.log('Fetching notification for title:', title); // Log the title
        const fetchNotification = async () => {
            try {
                const response = await axios.get(`https://apiviztech.in:6101/api/notificationsd/${encodeURIComponent(title)}`);
                setNotification(response.data);
            } catch (error) {
                console.error('Error fetching notification:', error);
            }
        };
        fetchNotification();
    }, [title]);
    // Split content by new lines and render each line as a list item
    const renderContentWithBullets = (content) => {
        const lines = content.split('\n');
        return (
            <Box>
            {lines.map((line, index) => (
                <Typography
                    key={index}
                    variant="body1"
                    sx={{
                        textAlign: 'justify',
                        fontSize: '0.95rem',
                        display: 'block',
                        marginBottom: 1,
                        textIndent: '-1em',
                        paddingLeft: '1em',
                    }}
                >
                    • {line}
                </Typography>
            ))}
        </Box>
        );
    };

    return (
        <Container maxWidth="sm" sx={{ mt: 4, mb: 4 }}>
           {/*  {notification ? (
                <>
                    <Typography variant="h4">{notification.title}</Typography>
                    <Typography variant="body1">{notification.content}</Typography>
                </>
            ) : (
                <Typography variant="h6">Loading...</Typography>
            )} */}
             {notification ? (
                <Card
                    sx={{
                        boxShadow: 6,
                        borderRadius: 4,
                        backgroundColor: '#f9f9f9',
                        padding: 2,
                        maxWidth: '100%',
                       
                        //wordWrap: 'break-word',
                        overflow: 'hidden',
                    }}
                >
                    <CardHeader
                        title={
                            <Typography
                                variant="h6"
                                component="div"
                                sx={{
                                    fontWeight: 'bold',
                                    color: '#3f51b5',
                                    textAlign: 'center',
                                    overflowWrap: 'break-word',
                                    wordBreak: 'break-word',
                                    whiteSpace: 'normal',
                                   /*  textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap', */
                                }}
                            >
                                {notification.title}
                            </Typography>
                        }
                    />
                     <CardContent>
                        <Typography
                            variant="body1"
                            sx={{
                                textAlign: 'justify',
                                color: '#333',
                                lineHeight: 1.6,
                                fontSize: '0.95rem',
                                whiteSpace: 'pre-line', // Preserve new lines
                                overflowWrap: 'break-word',
                                wordWrap: 'break-word',
                                hyphens: 'auto',
                                maxHeight: '60vh',
                                overflowY: 'auto',
                            }}
                        >
                            {renderContentWithBullets(notification.content)}
                        </Typography>
                    </CardContent>
                </Card>
            ) : (
                <Typography variant="h6" sx={{ textAlign: 'center', mt: 4 }}>
                    Loading...
                </Typography>
            )}
        </Container>
    );
};

export default NotificationDetail;
