import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, TextField, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import axios from 'axios';

const CourseManager = () => {
  const [courses, setCourses] = useState([]);
  const [open, setOpen] = useState(false);
  const [newCourse, setNewCourse] = useState({ title: '', description: '', link: '', img_url: '' });
  const [file, setFile] = useState(null);  // To store image file
  const [editingCourse, setEditingCourse] = useState(null);

  useEffect(() => {
    fetchCourses();
  }, []);

  const fetchCourses = async () => {
    //const result = await axios.get('http://localhost:5000/api/courses');
    //24-10-24//
    const result = await axios.get('https://apiviztech.in:6101/api/courses');
    setCourses(result.data);
  };

  const handleOpen = (course = null) => {
    setEditingCourse(course);
    if (course) setNewCourse(course);  // Set form data for editing
    else setNewCourse({ title: '', description: '', link: ''});
    setFile(null);  // Clear file input
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingCourse(null);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setNewCourse({ ...newCourse, [name]: value });
  };
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  /* const handleSubmit = async () => {
    if (editingCourse) {
      // Update course
      await axios.put(`http://localhost:5000/api/courses/${editingCourse.id}`, newCourse);
    } else {
      // Add new course
      await axios.post('http://localhost:5000/api/courses', newCourse);
    }
    handleClose();
    fetchCourses();
  };

  const handleDelete = async (id) => {
    await axios.delete(`http://localhost:5000/api/courses/${id}`);
    fetchCourses();
  }; */
  const handleSubmit = async () => {
    const formData = new FormData();
    formData.append('title', newCourse.title);
    formData.append('description', newCourse.description);
    formData.append('link', newCourse.link);

    if (file) {
      formData.append('img_file', file);  // Append image file
    }

    if (editingCourse) {
      // Update course
      //await axios.put(`http://localhost:5000/api/courses/${editingCourse.id}`, formData, {
      //24-10-24//
      await axios.put(`https://apiviztech.in:6101/api/courses/${editingCourse.id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    } else {
      // Add new course
      //await axios.post('http://localhost:5000/api/courses', formData, {
      //24-10-24//
      await axios.post('https://apiviztech.in:6101/api/courses', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
    }

    handleClose();
    fetchCourses();
  };
  const handleDelete = async (id) => {
    //await axios.delete(`http://localhost:5000/api/courses/${id}`);
    //24-10-24//
    await axios.delete(`https://apiviztech.in:6101/api/courses/${id}`);
    fetchCourses();
  };

  return (
   
      <div>
      <Button variant="contained" color="primary" onClick={() => handleOpen()}>Add New Course</Button>

      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Title</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Link</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {courses.map((course) => (
              <TableRow key={course.id}>
                <TableCell>{course.title}</TableCell>
                <TableCell>{course.description}</TableCell>
                <TableCell>{course.link}</TableCell>
                <TableCell>
                  {/* <img src={course.img_url}  */}
                  <img
                  //src={`http://localhost:5000${course.img_url}`}
                  //24-10-24//
                  src={`https://apiviztech.in:6101${course.img_url}`}
                  alt={course.title} 
                  style={{ width: '100px', height: '60px' }} />
                </TableCell>
                <TableCell>
                  <Button variant="contained" color="primary" onClick={() => handleOpen(course)}>Edit</Button>
                  <Button variant="contained" color="secondary" onClick={() => handleDelete(course.id)} sx={{ ml: 2 }}>Delete</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Dialog for Adding/Editing Courses */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{editingCourse ? 'Edit Course' : 'Add New Course'}</DialogTitle>
        <DialogContent>
          <TextField label="Title" name="title" fullWidth value={newCourse.title} onChange={handleChange} margin="dense" />
          <TextField label="Description" name="description" fullWidth value={newCourse.description} onChange={handleChange} margin="dense" />
          <TextField label="Link" name="link" fullWidth value={newCourse.link} onChange={handleChange} margin="dense" />
          <input type="file" name="img_file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">Cancel</Button>
          <Button onClick={handleSubmit} color="primary">{editingCourse ? 'Update' : 'Add'}</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CourseManager;
