// src/admin/NotificationPage.js
import React, { useState, useEffect } from 'react';
import {
    Box, TextField, Button, Typography, Container, CssBaseline,
    Snackbar, Alert, IconButton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

const NotificationPage = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const [message, setMessage] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [isEditMode, setIsEditMode] = useState(false);
    const [notifications, setNotifications] = useState([]); // State for storing notifications
    const navigate = useNavigate();
    const { notificationId } = useParams(); // Get notification ID from URL

    useEffect(() => {
        fetchNotifications(); // Fetch notifications on component mount
        if (notificationId) {
            fetchNotification(notificationId);
        }
    }, [notificationId]);

    const fetchNotifications = async () => {
        try {
            const response = await axios.get('https://apiviztech.in:6101/api/notificationss'); // Adjust your API endpoint as necessary
            setNotifications(response.data);
        } catch (error) {
            setMessage('Failed to fetch notifications.');
            setOpenSnackbar(true);
        }
    };

    const fetchNotification = async (id) => {
        try {
            const response = await axios.get(`https://apiviztech.in:6101/api/notifications/${id}`);
            setTitle(response.data.title);
            setContent(response.data.content);
            setIsEditMode(true);
        } catch (error) {
            setMessage('Failed to fetch notification.');
            setOpenSnackbar(true);
        }
    };

    const handleSave = async () => {
        try {
            if (isEditMode) {
                const response = await axios.put(`https://apiviztech.in:6101/api/notifications/${notificationId}`, { title, content });
                setMessage('Notification updated successfully!');
            } else {
                const response = await axios.post('https://apiviztech.in:6101/api/notification', { title, content });
                setMessage('Notification saved successfully!');
                navigate(`/notifications/${encodeURIComponent(title)}`);
            }
            setOpenSnackbar(true);
            fetchNotifications(); // Refresh the notification list after saving
        } catch (error) {
            setMessage('Failed to save notification.');
            setOpenSnackbar(true);
        }
    };

    const handleDelete = async (id) => {
        try {
            await axios.delete(`https://apiviztech.in:6101/api/notifications/${id}`);
            setMessage('Notification deleted successfully!');
            fetchNotifications(); // Refresh the notification list after deletion
            setOpenSnackbar(true);
        } catch (error) {
            setMessage('Failed to delete notification.');
            setOpenSnackbar(true);
        }
    };

    const handleEdit = (notification) => {
        setTitle(notification.title);
        setContent(notification.content);
        setIsEditMode(true);
        navigate(`/admin/notifications/${notification.id}`); // Navigate to the edit page
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    return (
        <Container component="main" maxWidth="lg">
            <CssBaseline />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    mt: 5,
                }}
            >
                <Typography variant="h5">{isEditMode ? 'Edit Notification' : 'Create Notification'}</Typography>
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                />
                <TextField
                    margin="normal"
                    required
                    fullWidth
                    label="Content"
                    multiline
                    rows={4}
                    value={content}
                    onChange={(e) => setContent(e.target.value)}
                />
                <Button variant="contained" onClick={handleSave} sx={{ mt: 2 }}>
                    {isEditMode ? 'Update' : 'Save'}
                </Button>
            </Box>

            {/* Notifications Table */}
            <TableContainer component={Paper} sx={{ mt: 5 }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Content</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {notifications.map((notification) => (
                            <TableRow key={notification.id}>
                                <TableCell>{notification.title}</TableCell>
                                <TableCell>{notification.content}</TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleEdit(notification)}>
                                        <EditIcon />
                                    </IconButton>
                                    <IconButton onClick={() => handleDelete(notification.id)} sx={{ color: 'red' }}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity="success" sx={{ width: '100%' }}>
                    {message}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default NotificationPage;
