// src/admin/Register.js
import React, { useState } from 'react';
import { Container, TextField, Button, Typography, MenuItem, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Register.css'; // Custom styles for background

const Register = () => {
    const navigate = useNavigate();

    const [formData, setFormData] = useState({
        userType: 'admin', // Default selection for admin
        fullName: '',
        email: '',
        password: '',
        mobile: ''
    });

    const [errors, setErrors] = useState({});
    
    // Handle input changes
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Validate form inputs
    const validateForm = () => {
        const validationErrors = {};

        // Full name validation (only characters)
        if (!/^[a-zA-Z\s]+$/.test(formData.fullName)) {
            validationErrors.fullName = 'Full name should contain only letters';
        }

        // Email validation
        if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
            validationErrors.email = 'Enter a valid email';
        }

        // Password validation (8 characters, 1 uppercase, 1 special char, 1 digit)
        if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(formData.password)) {
            validationErrors.password = 'Password must be 8 characters, contain 1 uppercase, 1 digit, and 1 special character';
        }

        // Mobile validation
        if (!/^\d{10}$/.test(formData.mobile)) {
            validationErrors.mobile = 'Enter a valid 10-digit mobile number';
        }

        setErrors(validationErrors);
        return Object.keys(validationErrors).length === 0;
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        
        // If form validation fails, do not proceed
        if (!validateForm()) return;

        try {
            // Check if email exists
            const response = await axios.post('https://apiviztech.in:6101/api/check-email', { email: formData.email });
            if (response.data.exists) {
                setErrors({ email: 'This email is already registered' });
                return;
            }

            // Hash password (this is typically done on the backend, but for simplicity, assume it's hashed)
            const hashedPassword = formData.password; // Replace with actual hash logic

            // Insert user record
            await axios.post('https://apiviztech.in:6101/api/register', {
                userType: 'admin', // Static value
                userTypeId: 2, // Static value for user type admin
                fullName: formData.fullName,
                email: formData.email,
                password: hashedPassword,
                mobile: formData.mobile
            });

            // Redirect to admin login page
            navigate('/admin/login');
        } catch (error) {
            console.error('Registration error:', error);
        }
    };

    return (
        <div className="register-page">
            <div className="register-container">
                <Container maxWidth="xs" sx={{ marginTop: 4 }}>
                    <Typography variant="h5" component="h1" gutterBottom>
                        Admin Registration
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        {/* User Type - Admin is default */}
                        <TextField
                            select
                            label="User Type"
                            fullWidth
                            value={formData.userType}
                            name="userType"
                            disabled // Fixed value as 'admin'
                        >
                            <MenuItem value="admin">Admin</MenuItem>
                        </TextField>
                        
                        {/* Full Name */}
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Full Name"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            error={!!errors.fullName}
                            helperText={errors.fullName}
                        />
                        
                        {/* Email */}
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            error={!!errors.email}
                            helperText={errors.email}
                        />
                        
                        {/* Password */}
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Password"
                            type="password"
                            name="password"
                            value={formData.password}
                            onChange={handleChange}
                            error={!!errors.password}
                            helperText={errors.password}
                        />
                        
                        {/* Mobile */}
                        <TextField
                            variant="outlined"
                            margin="normal"
                            fullWidth
                            label="Mobile Number"
                            name="mobile"
                            value={formData.mobile}
                            onChange={handleChange}
                            error={!!errors.mobile}
                            helperText={errors.mobile}
                        />

                        {/* Register Button */}
                        <Button type="submit" variant="contained" fullWidth sx={{ mt: 2 }}>
                            Register
                        </Button>
                    </Box>
                </Container>
            </div>
        </div>
    );
};

export default Register;
