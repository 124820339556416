import React, { useEffect, useState } from 'react';
import {
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
  TextField,
} from '@mui/material';
import axios from 'axios';
import * as XLSX from 'xlsx';

const Inquiry = () => {
  const [inquiries, setInquiries] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const recordsPerPage = 15; // Number of records per page

  // Fetch inquiries from the API
  useEffect(() => {
    const fetchInquiries = async () => {
      try {
        const response = await axios.get('https://apiviztech.in:6101/api/inquiries');
        setInquiries(response.data);
      } catch (error) {
        console.error('Error fetching inquiries:', error);
      }
    };
    fetchInquiries();
  }, []);

  // Export inquiries data to Excel
  const exportToExcel = () => {
    // If searchTerm is not empty, export filtered data; otherwise, export all inquiries
    const dataToExport = searchTerm ? filteredInquiries : inquiries;

    const worksheet = XLSX.utils.json_to_sheet(dataToExport);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Inquiries');
    XLSX.writeFile(workbook, 'Inquiries.xlsx');
  };

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    setCurrentPage(1); // Reset to first page when search term changes
  };

  // Filter inquiries based on search term
  const filteredInquiries = inquiries.filter((inquiry) => {
    const lowercasedSearchTerm = searchTerm.toLowerCase();
    return (
      inquiry.name.toLowerCase().includes(lowercasedSearchTerm) ||
      inquiry.email.toLowerCase().includes(lowercasedSearchTerm) ||
      inquiry.mobile.toLowerCase().includes(lowercasedSearchTerm) ||
      inquiry.message.toLowerCase().includes(lowercasedSearchTerm)
    );
  });

  // Get the current page's inquiries
  const currentInquiries = filteredInquiries.slice(
    (currentPage - 1) * recordsPerPage,
    currentPage * recordsPerPage
  );

  // Go to the next page
  const nextPage = () => {
    if (currentPage < Math.ceil(filteredInquiries.length / recordsPerPage)) {
      setCurrentPage(currentPage + 1);
    }
  };

  // Go to the previous page
  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom>
        Inquiries
      </Typography>

      {/* Search Input */}
      <TextField
        label="Search Inquiries"
        value={searchTerm}
        onChange={handleSearchChange}
        variant="outlined"
        size="small"
        fullWidth
        sx={{ mb: 2 }}
      />

      {/* Export Button */}
      <Button
        variant="contained"
        color="primary"
        onClick={exportToExcel}
        sx={{ mb: 2 }}
      >
        Export to Excel
      </Button>

      {/* Table of Inquiries */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Mobile</TableCell>
              <TableCell>Message</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {currentInquiries.length > 0 ? (
              currentInquiries.map((inquiry) => (
                <TableRow key={inquiry.id}>
                  <TableCell>{inquiry.name}</TableCell>
                  <TableCell>{inquiry.email}</TableCell>
                  <TableCell>{inquiry.mobile}</TableCell>
                  <TableCell>{inquiry.message}</TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell colSpan={4} align="center">No inquiries found</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination Controls */}
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
        <Button variant="outlined" onClick={prevPage} disabled={currentPage === 1}>
          Previous
        </Button>
        <Button
          variant="outlined"
          onClick={nextPage}
          disabled={currentPage === Math.ceil(filteredInquiries.length / recordsPerPage)}
        >
          Next
        </Button>
      </div>
    </Container>
  );
};

export default Inquiry;
