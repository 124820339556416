// src/components/Services.js
import React from 'react';
import { Container, Typography } from '@mui/material';

const Services = () => {
  return (
    <Container>
      <Typography variant="h4" sx={{ mt: 4 }}>Our Services</Typography>
      <Typography sx={{ mt: 2 }}>
        At Infokshetra Technologies, we offer specialized services in school management, IT training, and custom software development to help businesses succeed.
      </Typography>
    </Container>
  );
};

export default Services;
